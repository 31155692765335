const angular = require('angular');
const MarkerClusterer = require('@googlemaps/markerclusterer');
var markerCluster;

module.exports = /*@ngInject*/ function ($scope, $q, $window, $location, MapProvider, FilterProvider, RealEstateSpinner) {
    // pre spinner
    let map;
    let overlay; // vyznacena plocha na mape
    let drawingManager;
    let bounds = null;
    const infowindows = [];
    const mapStyle = [
        {"featureType": "landscape", "stylers": [{"saturation": -100}, {"lightness": 65}, {"visibility": "on"}]},
        {"featureType": "poi", "stylers": [{"saturation": -100}, {"lightness": 51}, {"visibility": "simplified"}]},
        {"featureType": "road.highway", "stylers": [{"saturation": -100}, {"visibility": "simplified"}]},
        {"featureType": "road.arterial", "stylers": [{"saturation": -100}, {"lightness": 30}, {"visibility": "on"}]},
        {"featureType": "road.local", "stylers": [{"saturation": -100}, {"lightness": 40}, {"visibility": "on"}]},
        {"featureType": "transit", "stylers": [{"saturation": -100}, {"visibility": "simplified"}]},
        {"featureType": "administrative.province", "stylers": [{"visibility": "off"}]},
        {"featureType": "water", "elementType": "labels", "stylers": [{"visibility": "on"}, {"lightness": -25}, {"saturation": -100}]},
        {"featureType": "water", "elementType": "geometry", "stylers": [{"hue": "#ffff00"}, {"lightness": -25}, {"saturation": -97}]}
    ];
    const mapCenter = {
        lat: 48.6750486,
        lng: 20.0580432
    };

    const icons = [
        '/images/pins/pin_1.png',
        '/images/pins/pin_2.png',
        '/images/pins/pin_3.png',
        '/images/pins/pin_4.png',
        '/images/pins/pin_5.png',
        '/images/pins/pin_6.png',
        '/images/pins/pin_7.png',
        '/images/pins/pin_8.png',
        '/images/pins/pin_9.png',
        '/images/pins/pin_10.png',
        '/images/pins/pin_11.png',
        '/images/pins/pin_12.png'
    ];

    $scope.spinner = RealEstateSpinner.proccess;
    $scope.inited = false;
    $scope.markers = [];
    $scope.types = [];
    $scope.species = [];
    $scope.location = [];
    $scope.locations = [];
    $scope.states = [];
    $scope.countries = [];
    $scope.regions = [];
    $scope.cities = [];
    $scope.categories = [];
    $scope.selectedType = '';

    $scope.filter = {
        coordinateType: '',
        coordinates: [],
        geoJSON: {
            box: [],
            polygon: [],
            type: ''
        },
        priceFrom: null,
        priceTo: null,
        sizeFrom: null,
        sizeTo: null,
        type: '',
        specie: '',
        state: '',
        country: '',
        region: [],
        city: [],
        categories: [],
        virtual: 0,
        new : 0,
        balcon: 0
    };

    $scope.advertTypes = {
        Byty: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_B.png'
        },
        Domy: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_D.png'
        },
        Pozemky: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_P.png'
        },
        Objekty: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_O.png'
        },
        'Developerské projekty': {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_DP.png'
        },
        Ostatné: {
            name: '',
            key: '',
            amount: 0,
            icon: '/images/pins/pin_10.png'
        },
    };

    const initMap = function () {
        map = new google.maps.Map(document.getElementById('filterMap'), {
            center: mapCenter,
            disableDefaultUI: true,
            zoomControl: true,
            styles: mapStyle,
            zoom: 7
        });

        drawingManager = new google.maps.drawing.DrawingManager({
            drawingControl: true,
            drawingMode: null,
            drawingControlOptions: {
                drawingModes: ['rectangle', 'polygon'],
                position: google.maps.ControlPosition.TOP_CENTER
            },
            rectangleOptions: {
                fillColor: 'red'
            },
            polygonOptions: {
                fillColor: 'red'
            }
        });
        drawingManager.setMap(map);

        // logika kedy zmazat vybranu plochu a zacat kreslit novu
        google.maps.event.addListener(drawingManager, 'rectanglecomplete', function (e) {
            drawingManager.setDrawingMode(null);
            rectangleComplete(e);
            overlay = e;
        });
        google.maps.event.addListener(drawingManager, 'polygoncomplete', function (e) {
            drawingManager.setDrawingMode(null);
            polygonComplete(e);
            overlay = e;
        });

        google.maps.event.addListener(drawingManager, 'drawingmode_changed', function (e) {
            if (overlay) {
                overlay.setMap(null);
            }
        });

        google.maps.event.addListenerOnce(map, 'idle', function () {
            // nastavenie css pre drawing manazera
            $(".gmnoprint").each(function () {
                var drawManagerPanel = $(this).find("[title='Zastavenie kresby']").parent().parent();

                drawManagerPanel.css({border: 'solid 2px red'});
            });

            loadMarkers();
        });
        /*
         google.maps.event.addListener(map,'dragend', function (evt) {
         var bounds = map.getBounds();
         
         var coords = [[bounds.getSouthWest().lat(),bounds.getSouthWest().lng()],[bounds.getNorthEast().lat(),bounds.getNorthEast().lng()]];
         
         loadMarkers("Rectangle",coords);
         }); */
    };

    init();

    $scope.polygonComplete = polygonComplete;
    $scope.rectangleComplete = rectangleComplete;
    $scope.loadMarkers = loadMarkers;
    $scope.filterMarkers = filterMarkers;
    $scope.showAllMarkers = showAllMarkers;

    $scope.$watch('location', function (location, oldVal) {
        if (location !== oldVal) {
            const isCountry = $scope.countries.some(function (country) {
                return country.id === location[0].id;
            });

            if (isCountry) {
                loadRegions(location[0].id);
                $scope.filter.country = location[0].id;
                $scope.filter.state = '';
            } else {
                $scope.filter.state = location[0].id;
                $scope.filter.country = '';
            }

            $scope.cities = [];
            $scope.regions = [];
            $scope.filter.region = [];
            $scope.filter.city = [];
        }
    }, true);

    $scope.$watch('filter.region', function (regionId, oldVal) {
        if (regionId !== oldVal) {
            loadCities(regionId);
            $scope.filter.city = [];
        }
    });

    $scope.$watch('filter.specie', function (specieId, oldVal) {
        if (specieId !== oldVal) {
            loadCategories(specieId);
            $scope.filter.category = '';
        }
    });

    function init() {
        const speciePromise = FilterProvider.loadSpecies()
                .then(function (species) {
                    $scope.species = species;

                    // nastavit nazvy pre typy nehnutelnost (kvoli mutacii bude iny nazov preto nemozno hardcode)
                    species.forEach(function (specie) {
                       // $scope.advertTypes[specie.label].name = specie.value;
                       // $scope.advertTypes[specie.label].key = specie.label;
                    });
                });

        const typePromise = FilterProvider.loadTypes()
                .then(function (types) {
                    $scope.types = types;
                });

        RealEstateSpinner.addPromise(speciePromise);
        RealEstateSpinner.addPromise(typePromise);

        loadStates();

        // listener pre "enter press"
        $('#mapFilterSection').find('input').keypress(function (e) {
            if ((event.keyCode || event.which) == 13) {
                loadMarkers();
            }
        });

        // init map podla parametrov z URL
        const data = {
            specie: $location.search().specie ? $location.search().specie : '',
            type: $location.search().type ? $location.search().type : '',
            state: $location.search().state ? $location.search().state : '',
            country: $location.search().country ? $location.search().country : '',
            //region  : $location.search().region ? $location.search().region : [],
            //city    : $location.search().city ? $location.search().city : [],
            priceFrom: $location.search().priceFrom ? $location.search().priceFrom : '',
            priceTo: $location.search().priceTo ? $location.search().priceTo : '',
            sizeFrom: $location.search().sizeFrom ? $location.search().sizeFrom : '',
            sizeTo: $location.search().sizeTo ? $location.search().sizeTo : '',
            balcon: $location.search().balcon ? true : 0,
            virtual: $location.search().virtual ? true : 0,
            new : $location.search().new ? true : 0
        };

        if (data.priceTo) {
            data.priceTo = parseInt(data.priceTo);
        }

        if (data.priceFrom) {
            data.priceFrom = parseInt(data.priceFrom);
        }

        if (data.sizeFrom) {
            data.sizeFrom = parseInt(data.sizeFrom);
        }

        if (data.sizeTo) {
            data.sizeTo = parseInt(data.sizeTo);
        }

        $scope.filter = angular.extend($scope.filter, data);

        /*if (data.country) {
         loadRegions(data.country);
         }*/

        /*if (data.region) {
         loadCities(data.region);
         }*/

        if (data.specie) {
            FilterProvider.loadCategories(data.specie)
                    .then(function (categories) {
                        categories.forEach(function (category) {
                            $scope.categories.push({
                                name: category.label,
                                key: category.value,
                                amount: 0,
                                icon: '',
                                selected: false
                            });
                        });

                        let inputCategories = angular.isDefined($location.search()['category[]']) ? $location.search()['category[]'] : [];

                        if (inputCategories && typeof inputCategories === 'string') {
                            inputCategories = [inputCategories];
                        }

                        if (Array.isArray(inputCategories) && inputCategories.length) {
                            $scope.categories.forEach(function (category) {
                                if (inputCategories.indexOf(category.key) !== -1) {
                                    category.selected = true;
                                }
                            });
                        }

                        addMapInitiator(initMap);
                    });
        } else {
            addMapInitiator(initMap);
        }
    }

    function addMapInitiator(initFnc) {
        if (!window._googleMapBoostrap) {
            window._googleMapBoostrap = [];
        }

        window._googleMapBoostrap.push(initFnc);

        if (!window.boostrapGoogleMaps) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=visualization,drawing,geometry&key=' + window.mapApiKey + '&callback=boostrapGoogleMaps';
            document.body.appendChild(script);

            window.boostrapGoogleMaps = function () {
                if (window._googleMapBoostrap) {
                    window._googleMapBoostrap.forEach(function (boostrapMap) {
                        boostrapMap();
                    });
                }
            }
        }
    }

    function rectangleComplete(e) {
        const NE = e.getBounds().getNorthEast();
        const SW = e.getBounds().getSouthWest();

        $scope.filter.coordinates = [
            [NE.lat(), NE.lng()],
            [SW.lat(), SW.lng()]
        ];

        // https://docs.mongodb.com/manual/reference/operator/query/box/
        $scope.filter.geoJSON.box = [
            // bottom left
            [SW.lng(), SW.lat()],
            // upper right
            [NE.lng(), NE.lat()]
        ];

        $scope.filter.geoJSON.type = 'Rectangle';
        $scope.filter.coordinateType = 'Rectangle';
    }

    function polygonComplete(e) {
        const coordinates = e.getPath().getArray();

        $scope.filter.coordinates = coordinates.map(function (coor) {
            return [coor.lat(), coor.lng()];
        });

        // https://docs.mongodb.com/manual/reference/operator/query/polygon/
        $scope.filter.geoJSON.polygon = coordinates.map(function (point) {
            return [point.lng(), point.lat()];
        });

        $scope.filter.geoJSON.type = 'Polygon';
        $scope.filter.coordinateType = 'Polygon';
    }

    function addMarkers(markersData) {
        const categories = [];

        bounds = new google.maps.LatLngBounds();

        markersData.forEach(function (markerData) {
            const position = {
                lat: markerData.position.latitude,
                lng: markerData.position.longitude
            };

            const marker = new google.maps.Marker({
                position: position,
                map: map,
                title: markerData.name,
                customInfo: {
                    specie: markerData.specie.label,
                }
            });

            let icon;

            // vytvorenie legendy
            if (markerData.category) {
                angular.forEach($scope.categories, function (category, index) {
                    if (category.name == markerData.category) {
                        if (categories.indexOf(category.name) == -1) {
                            categories.push(category.name);
                        }

                        icon = icons[categories.indexOf(category.name)];

                        category.icon = icon;
                        category.selected = true;
                        category.amount++;
                    }
                });
            } 

            /*  if (!$scope.filter.specie && markerData.specie.label.length > 0 ) {
             icon = $scope.advertTypes[markerData.specie.label].icon;
             $scope.advertTypes[markerData.specie.label].amount++;
             }*/
            
            icon = $scope.advertTypes[markerData.specie.label].icon;

            marker.setIcon(icon);

            const infoWindow = new google.maps.InfoWindow({
                content: [
                    '<div class="marker-info-window row">',
                    '<div class="col-xs-12 col-lg-4 pb-2">',
                    '<a href="' + markerData.url + '" target="_blank">',
                    '<img class="img-responsive" src="' + markerData.image + '">',
                    '</a>',
                    '</div>',
                    '<div class="col-xs-12 col-lg-8">',
                    '<h5>',
                    '<a href="' + markerData.url + '" target="_blank">',
                    '<i class="fa fa-external-link" aria-hidden="true"></i> ' + markerData.name,
                    '</a>',
                    '</h5>',
                    '<p>',
                    '<strong>' + markerData.price + '</strong>',
                    '</p>',
                    '</div>',
                    '</div>'
                ].join('')
            });

            infowindows.push(infoWindow);

            marker.addListener('click', function () {
                for (let i = 0; i < infowindows.length; i++) {
                    infowindows[i].close();
                }
                infoWindow.open(map, marker);
            });



            bounds.extend(marker.getPosition());
            $scope.markers.push(marker);
        });

        if (markersData.length > 0) {
            map.fitBounds(bounds);
        }
        
        if (markersData.length === 1) {
            map.setZoom(14);
        }
        
        const options = {
            minimumClusterSize: 5,
            gridSize: 25,
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        };

        markerCluster = new MarkerClusterer(map, $scope.markers, options);
    }

    function loadStates() {
        const promises = [];

        FilterProvider.loadStates()
                .then(function (states) {
                    $scope.states = states;

                    $scope.states.forEach(function (state) {
                        const promise = loadCountries(state.id).then(function (countries) {
                            state.countries = countries.map(function (country) {
                                country.placeholder = "<span class='country'>" + country.name + "</span>";
                                country.checked = $scope.filter.country === country.id;

                                return country;
                            });
                        });

                        promises.push(promise);
                    });

                    $q.all(promises).then(function () {
                        $scope.states.forEach(function (state) {
                            state.placeholder = "<span class='state'>" + state.name + "</span>";
                            state.checked = $scope.filter.state === state.id;

                            $scope.locations.push(state);
                            $scope.locations = $scope.locations.concat(state.countries);
                        });
                    });
                });
    }

    function loadCountries(stateId) {
        const promise = FilterProvider.loadCountries(stateId)
                .then(function (countries) {
                    $scope.countries = $scope.countries.concat(countries);

                    return countries;
                });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function loadRegions(countryId) {
        const promise = FilterProvider.loadRegions(countryId)
                .then(function (regions) {
                    $scope.regions = regions;

                    const inputRegions = angular.isDefined($location.search()['region[]']) ? $location.search()['region[]'] : [];

                    if (inputRegions.length) {
                        $scope.regions.forEach(function (region) {
                            if (inputRegions.indexOf(region.id) !== -1) {
                                $scope.filter.region.push(region.id);
                            }
                        });
                    }
                });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function loadCities(regionId) {
        const promise = FilterProvider.loadCities(regionId)
                .then(function (cities) {
                    $scope.cities = cities;
                    var cities = angular.isDefined($location.search()['city[]']) ? $location.search()['city[]'] : [];

                    if (cities.length) {
                        $scope.cities.forEach(function (city) {
                            if (cities.indexOf(city.id) !== -1) {
                                $scope.filter.city.push(city.id);
                            }
                        });
                    }
                });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function loadCategories(specie) {
        $scope.categories = [];

        const promise = FilterProvider.loadCategories(specie)
                .then(function (categories) {
                    categories.forEach(function (category) {
                        $scope.categories.push({
                            name: category.label,
                            key: category.value,
                            amount: 0,
                            icon: '',
                            selected: false
                        });
                    });
                });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function clearMarkers() {
        for (let i = 0; i < $scope.markers.length; i++) {
            $scope.markers[i].setMap(null);
        }
        if (markerCluster) {
            markerCluster.clearMarkers();
        }
        $scope.markers = [];

    }

    function clearAdvertTypes() {
        $scope.selectedType = '';

        angular.forEach($scope.advertTypes, function (type) {
            type.amount = 0;
        });
    }

    function filterMarkers(type) {
        const visibleBounds = new google.maps.LatLngBounds();

        $scope.filter.specie = type;
        $scope.selectedType = type;

        $scope.markers.forEach(function (marker) {
            if (marker.customInfo.specie == type) {
                marker.setVisible(true);
                visibleBounds.extend(marker.getPosition());
            } else {
                marker.setVisible(false);
            }
        });

        map.fitBounds(visibleBounds);
    }

    function showAllMarkers() {
        $scope.selectedType = '';
        $scope.filter.specie = '';

        for (let i = 0; i < $scope.markers.length; i++) {
            $scope.markers[i].setVisible(true);
        }

        map.fitBounds(bounds);
    }

    function resetCategories() {
        angular.forEach($scope.categories, function (category) {
            category.amount = 0;
            category.icon = '';
        });
    }

    function loadMarkers(type, coordinates) {
        $scope.inited = false;

        if (overlay) {
            overlay.setMap(null);
        }

        if ($scope.filter.specie) {
            clearAdvertTypes();
        }

        clearMarkers();
        clearAdvertTypes();
        resetCategories();

        $scope.filter.categories = [];
        $scope.categories.forEach(function (category) {
            if (category.selected) {
                $scope.filter.categories.push(category.key);
            }
        });

        if (!angular.isUndefined(type)) {
            $scope.filter.coordinateType = type;
        }
        if (!angular.isUndefined(coordinates)) {
            $scope.filter.coordinates = coordinates;
        }

        const promise = MapProvider.loadMarkers($scope.filter)
                .then(function (markers) {
                    let markerForMap = [];
                    if (markers && markers.length > 0) {
                        markers.forEach(function (marker) {
                            if (angular.isDefined(marker.position)) {
                                if (angular.isDefined(marker.position.latitude) && angular.isNumber(marker.position.latitude)) {
                                    if (angular.isDefined(marker.position.longitude) && angular.isNumber(marker.position.longitude)) {
                                        markerForMap.push(marker);
                                    }
                                }
                            }
                        });
                    }
                    addMarkers(markerForMap);
                    $scope.filter.coordinates = [];
                    $scope.filter.coordinateType = '';
                    $scope.filter.geoJSON = {
                        box: [],
                        polygon: [],
                        type: ''
                    };
                    $scope.realestates = markerForMap;
                });

        RealEstateSpinner.addPromise(promise);

        return promise;
    }

    function resizeMap() {
        setTimeout(function () {
            google.maps.event.trigger(map, 'resize');
            map.setCenter(mapCenter);

            if (bounds) {
                map.fitBounds(bounds);
            }
        }, 100);
    }

    $(document).ready(function () {
        $('.openMapButton').on('click', function () {
            resizeMap();
        });
    });
};